import { render, staticRenderFns } from "./change-phone.vue?vue&type=template&id=61230725&scoped=true&"
import script from "./change-phone.ts?vue&type=script&lang=ts&"
export * from "./change-phone.ts?vue&type=script&lang=ts&"
import style0 from "./change-phone.vue?vue&type=style&index=0&id=61230725&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61230725",
  null
  
)

export default component.exports